function isIE(): boolean {
    let appName = navigator.appName, userAgent = navigator.userAgent;
    if (appName === 'Microsoft Internet Explorer') {
        return true;
    } else if (appName === 'Netscape' && /Trident/.test(userAgent)) { // IE 11
        return true;
    }
    return false;
}

function isEdge(): boolean {
    return /Edg/.test(navigator.userAgent);
}

function isFirefox(): boolean {
    return /Firefox/.test(navigator.userAgent);
}

function isOpera(): boolean {
    return /Opera|OPR/.test(navigator.userAgent);
}

function isSamsungBrowser(): boolean {
    return /SamsungBrowser/.test(navigator.userAgent);
}

function isChrome(): boolean {
    if (/CriOS/.test(navigator.userAgent)) { //ios chrome
        return true;
    }
    return !!window.chrome //chromium browser
        && /Chrome/.test(navigator.userAgent)
        && /Google/.test(navigator.vendor);
}

function isSafari(): boolean {
    return (/Apple/.test(navigator.vendor)) && !(/CriOS/.test(navigator.userAgent));
}

export function getBrowserName(): string {
    if (isIE()) return 'IE';
    if (isEdge()) return 'Edge';
    if (isOpera()) return 'Opera';
    if (isFirefox()) return 'Firefox';
    if (isSamsungBrowser()) return 'SamsungBrowser';
    if (isChrome()) return 'Chrome';
    if (isSafari()) return 'Safari';
    return null;
}