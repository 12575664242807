// see also
// https://www.npmjs.com/package/flashdetect
// http://www.prodevtips.com/2008/11/20/detecting-flash-player-version-with-javascript/

export function getFlashVersionStr(): string {
    let SHOCKWAVE_FLASH = 'Shockwave Flash',
        SHOCKWAVE_FLASH_AX = 'ShockwaveFlash.ShockwaveFlash',
        FLASH_MIME_TYPE = "application/x-shockwave-flash",

        nav = navigator,
        plugins = nav.plugins,
        mimes = nav.mimeTypes
    ;

    if (!!plugins && typeof plugins[SHOCKWAVE_FLASH] === "object") {
        let desc = plugins[SHOCKWAVE_FLASH].description;
        if (!!desc && !(!!mimes && !!mimes[FLASH_MIME_TYPE] && !mimes[FLASH_MIME_TYPE].enabledPlugin)) {
            return desc;
        }
    } else if (!!window.ActiveXObject) {
        try {
            let ax = new ActiveXObject(SHOCKWAVE_FLASH_AX);
            if (ax) {
                let version = ax.GetVariable("$version");
                if (!!version) {
                    return version;
                }
            }
        } catch (e) {
        }
    }
    return '';
}

export function getFlashVersion(): string {
    let m = getFlashVersionStr().replace(/\D+/g, ",").match(/^,?(.+),?$/);
    if (m && m.length > 0) {
        return m[1];
    }
    return '';
}

export function hasFlashPlugin(): boolean {
    let SHOCKWAVE_FLASH = 'Shockwave Flash',
        FLASH_MIME_TYPE = "application/x-shockwave-flash",
        nav = navigator,
        plugins = nav.plugins,
        mimes = nav.mimeTypes
    ;

    if (!!plugins && typeof plugins[SHOCKWAVE_FLASH] === "object") {
        let desc = plugins[SHOCKWAVE_FLASH].description;
        if (!!desc && !(!!mimes && !!mimes[FLASH_MIME_TYPE] && !mimes[FLASH_MIME_TYPE].enabledPlugin)) {
            return true;
        }
    }
    return false;
}