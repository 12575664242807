export type MimeTypeInfo = {
    description: string,
    suffixes: string,
    type: string,
}

export function getMimeTypes(): MimeTypeInfo[] {
    let result = [], mimes = navigator.mimeTypes;
    try {
        for (let i = 0; i < mimes.length; i++) {
            result.push({
                'description': mimes[i].description,
                'suffixes': mimes[i].suffixes,
                'type': mimes[i].type,
            });
        }
    } catch (err) {
    }
    return result;
}