export function domReady(callback: () => void): void {
    if (!!document.attachEvent) {
        document.attachEvent("onreadystatechange", function () {
            if (document.readyState === "complete") {
                return callback();
            }
        });
    } else if (!!document.addEventListener) {
        document.addEventListener("DOMContentLoaded", function () {
            return callback();
        });
    } else {
        console.log('Fail')
    }
}