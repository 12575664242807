import objectHash from "object-hash"
import {getPlugins, PluginInfo} from "./plugins";
import {getMimeTypes, MimeTypeInfo} from "./mime-types";
import {getActiveXObjects, getActiveXSupport} from "./activex";
import {getScreenInfo, ScreenInfo} from "./screen";
import {getFlashVersion, getFlashVersionStr, hasFlashPlugin} from "./flash";
import {getBrowserName} from "./browser-name";
import {getIntlInfo} from "./intl";
import {hasIndexedDB, hasLocalStorage, hasSessionStorage} from "./storages";
import {getDoNotTrack} from "./do-not-track";
import {getTouchSupport, TouchSupport} from "./touch-support";
import {getBatteryApiSupport} from "./battery-api";
import {getLanguage} from "./language";
import {getIsMobile} from "./mobile";
import {withIframe} from '../helpers/with-iframe'

import {getFonts} from "./fonts";

import {
    getCanvasFp,
    getWebglFp,
    getWebGlParams,
    getWebglVendorAndRenderer,
    isCanvasSupported,
    isWebGlSupported, WebGlParams
} from "./canvas-webgl";

import {
    getHasLiedBrowser,
    getHasLiedLanguages,
    getHasLiedOs,
    getHasLiedResolution
} from "./fake-browser";

import {
    getNavigatorProperty,
    NavigatorProperties,
    NavigatorPropertyType
} from "./navigator-property";


type NavigatorPropertyContainer = {
    [key in NavigatorPropertyType]?: string | number;
}

export type Fingerprint = NavigatorPropertyContainer & {
    plugins: PluginInfo[],
    mimeTypes: MimeTypeInfo[],
    activeXSupport: boolean,
    activeXObjects: string[],
    screen: ScreenInfo,

    hasFlash: boolean,
    flashStr: string,
    flash: string,
    hasFlashPlugin: boolean,

    ua: string,
    isMobile: boolean,
    browserName: string,
    ul: string,
    devicePixelRatio: number,
    timezoneOffset: number,
    intl: Intl.ResolvedDateTimeFormatOptions,

    hasSessionStorage: boolean,
    hasLocalStorage: boolean,
    hasIndexedDB: boolean,
    doNotTrack: string,
    touchSupport: TouchSupport,
    batteryApiSupport: boolean,

    canvasFp: string,
    webglFp: string,
    webglVendor: string,
    webglParams: WebGlParams,

    hasLiedLanguages: boolean,
    hasLiedResolution: boolean,
    hasLiedOs: boolean,
    hasLiedBrowser: boolean,

    fonts: string[],
    fonts2?: string[],
}

export async function getFingerprint(): Promise<Fingerprint> {
    let flashStr = getFlashVersionStr();
    let canvasFp = isCanvasSupported() ? getCanvasFp() : null;
    let webglFp = isWebGlSupported() ? getWebglFp() : null;

    let result: Fingerprint = {
        plugins: getPlugins(),
        mimeTypes: getMimeTypes(),
        activeXSupport: getActiveXSupport(),
        activeXObjects: getActiveXObjects(),
        screen: getScreenInfo(),
        hasFlash: !!flashStr,
        flashStr: flashStr,
        flash: getFlashVersion(),
        hasFlashPlugin: hasFlashPlugin(),
        ua: navigator.userAgent,
        isMobile: getIsMobile(),
        browserName: getBrowserName(),
        ul: getLanguage(),

        devicePixelRatio: window.devicePixelRatio || null,
        timezoneOffset: new Date().getTimezoneOffset(),
        intl: getIntlInfo(),

        hasSessionStorage: hasSessionStorage(),
        hasLocalStorage: hasLocalStorage(),
        hasIndexedDB: hasIndexedDB(),
        doNotTrack: getDoNotTrack(),
        touchSupport: getTouchSupport(),
        batteryApiSupport: getBatteryApiSupport(),

        canvasFp: canvasFp ? objectHash(canvasFp) : null,
        webglFp: webglFp ? objectHash(webglFp) : null,
        webglVendor: isWebGlSupported() ? getWebglVendorAndRenderer() : null,
        webglParams: isWebGlSupported() ? getWebGlParams() : null,

        hasLiedLanguages: getHasLiedLanguages(),
        hasLiedResolution: getHasLiedResolution(),
        hasLiedOs: getHasLiedOs(),
        hasLiedBrowser: getHasLiedBrowser(),

        fonts: getFonts(),
    };

    // result.fonts2 = await withIframe((_, contentWindow) => getFonts(contentWindow.document));

    for (const key of NavigatorProperties) {
        let prop = getNavigatorProperty(key);
        if (prop !== undefined) {
            result[key] = prop;
        }
    }

    return result
}