export type TouchSupport = {
    maxTouchPoints: number,
    touchEvent: boolean,
    touchStart: boolean,
    documentTouch: boolean,
}

export function getTouchSupport(): TouchSupport {
    let maxTouchPoints = 0;
    let touchEvent = false;

    if (typeof navigator.maxTouchPoints !== 'undefined') {
        maxTouchPoints = navigator.maxTouchPoints;
    } else if (typeof navigator.msMaxTouchPoints !== 'undefined') {
        maxTouchPoints = navigator.msMaxTouchPoints;
    }

    try {
        document.createEvent('TouchEvent');
        touchEvent = true;
    } catch (_) { /* squelch */
    }

    let touchStart = 'ontouchstart' in window;
    return {
        'maxTouchPoints': maxTouchPoints,
        'touchEvent': touchEvent,
        'touchStart': touchStart,
        'documentTouch': !!(window.DocumentTouch && document instanceof DocumentTouch)
    };
}