//https://learn.javascript.ru/cookie

export type CookieOptions = {
    expires?: number | Date | string,
    ['max-age']?: number,
    path?: string,
    domain?: string,
    secure?: boolean,
    httpOnly?: boolean,
    samesite?: 'strict' | 'lax' | 'none'
}

function convertExpires(expires: number | Date | string): string {
    if (typeof expires === "number") { //seconds
        let d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        return d.toUTCString();
    }

    if (expires instanceof Date) {
        return expires.toUTCString();
    }

    return expires;
}

export function setCookie(name: string, value: string, options: CookieOptions = {}) {
    if (!!options.expires) {
        options.expires = convertExpires(options.expires)
    }

    let result = `${name}=${encodeURIComponent(value)}`;

    for (const key in options) {
        if (options.hasOwnProperty(key)) {
            let val = options[key];
            if (val === true) {
                result += `; ${key}`
            } else {
                result += `; ${key}=${val}`
            }
        }
    }

    document.cookie = result;
}

export function getCookie(name) {
    // noinspection RegExpRedundantEscape
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined
}