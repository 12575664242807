//https://developer.mozilla.org/ru/docs/Web/API/Navigator/doNotTrack

export function getDoNotTrack(): string {
    if (navigator.doNotTrack)
        return navigator.doNotTrack;

    if (navigator.msDoNotTrack)
        return navigator.msDoNotTrack;

    if (window.doNotTrack)
        return window.doNotTrack;

    return null;
}