export const NavigatorProperties = [
    'language',
    'languages',
    'userLanguage',
    'systemLanguage',
    'browserLanguage',

    'vendor',
    'vendorSub', //always empty

    'appCodeName',
    'appName',
    'appVersion',
    'product', // always "Gecko"
    'productSub',
    'platform',
    'oscpu',
    'buildID',

    'hardwareConcurrency',
    'deviceMemory',
    'webdriver',

] as const;

//https://stackoverflow.com/questions/45251664/typescript-derive-union-type-from-tuple-array-values
export type NavigatorPropertyType = typeof NavigatorProperties[number]; // 'language'|'languages'|...;


export function getNavigatorProperty(key: NavigatorPropertyType): string | number | undefined {
    return navigator[key];
}