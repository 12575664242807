import 'core-js/features/promise';
import 'core-js/features/array/some';
import 'core-js/features/array/filter';
import 'core-js/features/array/map';

import {domReady} from './helpers/dom-ready'
import {createWriter} from './writer'

domReady(async () => {
    let writer = createWriter();
    await writer.write();
});