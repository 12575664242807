export type ScreenInfo = {
    width: number,
    height: number,
    availWidth: number,
    availHeight: number,
    pixelDepth: number,
    colorDepth: number,
}

export function getScreenInfo(): ScreenInfo {
    let result = {
        'width': 800,
        'height': 600,
        'availWidth': 800,
        'availHeight': 600,
        'pixelDepth': 24,
        'colorDepth': 24
    };

    try {
        result['width'] = screen.width;
        result['height'] = screen.height;
        result['availWidth'] = screen.availWidth;
        result['availHeight'] = screen.availHeight;
        result['pixelDepth'] = screen.pixelDepth;
        result['colorDepth'] = screen.colorDepth;
        // result['orientation'] = screen.orientation || null;
    } catch (err) {
    }
    return result;
}