import axios from 'axios'
import {getFingerprint} from "./fingerprint/fingerprint";
import {getCookie, setCookie} from "./helpers/cookie-helper";

export interface FingerprintWriter {
    write(): Promise<void>
}

export function createWriter(): FingerprintWriter {
    let debug = (typeof window.FP_DEBUG !== 'undefined') ? window.FP_DEBUG : false;
    if (debug)
        return new FingerprintScreenWriter();
    else
        return new FingerprintServerWriter();
}

//for backward compatibility
function toJSON(obj) {
    return JSON.stringify(obj, function (k, v) {
        if (v === undefined) {
            return null;
        }
        return v;
    }, 2)
}

class FingerprintScreenWriter implements FingerprintWriter {
    async write() {
        let data = await getFingerprint();
        console.log(data);
        let div = document.createElement('div');
        div.innerHTML = '<pre><code>' + toJSON(data) + '</code></pre>';
        document.body.appendChild(div);
    }
}

class FingerprintServerWriter implements FingerprintWriter {
    async write() {
        let cookieName = 'fp_tracked';
        let expires = 60 * 60 * 24; //1 day

        if (!getCookie(cookieName)) {
            let data = await getFingerprint();
            try {
                await axios.post('/fp/write', toJSON(data), {headers: {'Content-Type': 'application/json'}});
                setCookie(cookieName, "true", {expires: expires})
            } catch (err) {
                console.log(err);
            }
        }
    }
}
