export function hasSessionStorage(): boolean {
    try {
        return !!window.sessionStorage;
    } catch (e) {
        return true; // SecurityError when referencing it means it exists
    }
}

export function hasLocalStorage(): boolean {
    try {
        return !!window.localStorage;
    } catch (e) {
        return true;// SecurityError when referencing it means it exists
    }
}

export function hasIndexedDB(): boolean {
    try {
        return !!window.indexedDB;
    } catch (e) {
        return true; // SecurityError when referencing it means it exists
    }
}