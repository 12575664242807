import {MimeTypeInfo} from './mime-types';

export type PluginInfo = {
    description: string,
    filename: string,
    name: string,
    version?: string,
    mimes: MimeTypeInfo[],
}

export function getPlugins(): PluginInfo[] {
    let regularPlugins = getRegularPlugins(),
        nonIterPlugins = getNonIterablePlugins(),
        len = nonIterPlugins.length;

    for (let i = 0; i < len; ++i) {
        let p = nonIterPlugins[i];
        let contains = regularPlugins.some(x => x.name === p.name);
        if (!contains) {
            regularPlugins.push(p);
        }
    }
    return regularPlugins;
}

function getRegularPlugins(): PluginInfo[] {
    let result = [], plugins = navigator.plugins, pl = plugins.length;
    try {
        for (let i = 0; i < pl; ++i) {
            let plugin = plugins[i], mimes = [], ml = plugin.length;

            for (let j = 0; j < ml; ++j) {
                let mime = plugin[j];
                mimes.push({
                    'description': mime.description,
                    'suffixes': mime.suffixes,
                    'type': mime.type
                });
            }

            result.push({
                'mimes': mimes,
                'description': plugin.description,
                'filename': plugin.filename,
                'name': plugin.name,
                'version': plugin.version,
            });
        }
    } catch (err) {
    }

    return result;
}

function getNonIterablePlugins(): PluginInfo[] {
    let plugin_names = ['2020 3D Viewer for IKEA v7.1.0.19',
        'Adobe Acrobat',
        'Adobe Acrobat NPAPI Plug-in, Version 15.023.20053',
        'Adobe Acrobat NPAPI Plug-in, Version 17.012.20098',
        'Adobe Reader 9.1',
        'AdobeExManDetect',
        'Chrome PDF Plugin',
        'Chrome PDF Viewer',
        'Chromium PDF Plugin',
        'Chromium PDF Viewer',
        'Citrix Online Web Deployment Plugin 1.0.0.104',
        'Default Browser Helper',
        'DivX Browser Plug-In',
        'DivX Plus Web Player',
        'DivX VOD Helper Plug-in',
        'DivX Web Player',
        'DivX® Web Player',
        'DjView-4.7',
        'Edge PDF Viewer',
        'Flip4Mac Windows Media Plugin',
        'Flip4Mac Windows Media Plugin 2.3.8 ',
        'GDL Object Web Plug-in 16.00',
        'GNOME Shell Integration',
        'Garmin Communicator Plug-In',
        'Google Earth Plug-in',
        'Google Earth Plugin',
        'Helix DNA Plugin: RealPlayer G2 Plug-In Compatible',
        'IcedTea-Web Plugin (using IcedTea-Web 1.5.3 (1.5.3-1))',
        'Intel® Identity Protection Technology',
        'JNEXT Framework',
        'Java Applet Plug-in',
        'Java(TM) Plug-in 1.6.0_18',
        'Java-Applet-Plug-In',
        'Maxthon PDF Viewer',
        'Microsoft Office 2010',
        'Microsoft Office Live Plug-in',
        'Microsoft Office Live Plug-in for Firefox',
        'Microsoft® DRM',
        'NVIDIA 3D VISION',
        'NVIDIA 3D Vision',
        'Native Client',
        'PDF (in WebKit-integriert)',
        'PDF-XChange Viewer',
        'Photo Gallery',
        'QuickTime Plug-in',
        'QuickTime Plug-in 7.6',
        'QuickTime Plug-in 7.6.6',
        'QuickTime Plug-in 7.6.9',
        'QuickTime Plug-in 7.7',
        'QuickTime Plug-in 7.7.1',
        'QuickTime Plug-in 7.7.3',
        'QuickTime Plug-in 7.7.6',
        'RealPlayer 9',
        'SharePoint Browser Plug-in',
        'Shockwave',
        'Shockwave Flash',
        'Shockwave for Director',
        'Silverlight Plug-In',
        'VLC Multimedia Plugin',
        'VLC Multimedia Plugin (compatible Videos 3.10.1)',
        'VLC Web Plugin',
        'WebEx Download Module',
        'WebKit-integrierte PDF',
        'Widevine Content Decryption Module',
        'Windows Live\x99 Photo Gallery',
        'Windows Live™ Photo Gallery',
        'Windows Live® Photo Gallery',
        'Windows Media Player Firefox Plugin',
        'Windows Media Player Plug-in',
        'Windows Media Player Plug-in 10 (compatible; Videos)',
        'Windows Media Player Plug-in Dynamic Link Library',
        'Windows Presentation Foundation',
        'YouTube Plug-in',
        'iPhotoPhotocast',
        'libmplayer.so',
        'libnapl.so',
        'mplayerplug-in is now gecko-mediaplayer 1.0.9'
    ], l = plugin_names.length, result = [];

    for (let i = 0; i < l; ++i) {
        let plugin: Plugin = navigator.plugins[plugin_names[i]];
        if (plugin) {
            let mimes = [], ml = plugin.length;
            for (let j = 0; j < ml; ++j) {
                let mime = plugin[j];
                mimes.push({
                    'description': mime.description,
                    'suffixes': mime.suffixes,
                    'type': mime.type
                });
            }

            result.push({
                'mimes': mimes,
                'description': plugin.description,
                'filename': plugin.filename,
                'name': plugin.name,
                'version': plugin.version,
            });
        }
    }
    return result;
}